.pageBody {
  min-height: 100%;
  padding-top: 5vh;
  position: relative;
}
.landingMain {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding-bottom: 2rem;
}
.pageContent {
  padding-top: 10vh;
  padding-bottom: 10vh;
}
.landingTitle {
  font-family: "Lora";
  font-weight: bold;
  font-size: 5rem;
  text-align: center;
  color: #0d2037;
}

#titleSecondLine {
  color: white;
}

.divider {
  min-height: 40rem;
  background-image: linear-gradient(#0d2037, #0d2037d5),
    url("../images/divBg1d.jpg");
  background-repeat: no-repeat, no-repeat;
  background-position: center, center;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.divMenu {
  color: white;
  position: relative;
}
.menuItem {
  text-align: center;
  font-family: "Lora";
}
.menuItem > * {
  font-size: 3rem;
  color: white;
  line-height: 7rem;
  transition: all;
  transition-duration: 100ms;
  cursor: pointer;
}
.menuItem > *:hover {
  color: var(--brass);
  transform: scale(1.1);
  transition: all;
  transition-duration: 100ms;
}
.menuItem + .menuItem {
  border-left-width: 1px;
  border-left-style: solid;
  border-left-color: rgba(255, 255, 255, 0.5);
}
.contentSection {
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  padding: 2.5vw;
  border-radius: 15px;
  margin-bottom: 5vw;
}
.aboutText {
  color: black;
  font-family: "Lora";
  position: relative;
  text-align: justify;
}

.aboutText > hr {
  width: 100%;
  height: 0.2rem;
  color: transparent;
  background-image: radial-gradient(var(--brass), transparent);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
}

#aboutImg {
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
  object-fit: contain;
}

#aboutProductText > hr {
  width: 100%;
  height: 0.2rem;
  color: transparent;
  background-image: radial-gradient(var(--brass), transparent);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
.aboutProductImage {
  object-fit: contain;
  width: 25rem;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.5);
}
#divProdImg {
  background-image: linear-gradient(#0d2037, #0d2037d5),
    url("../images/divBg2d.jpg");
  text-align: center;
}
#divProdImg > h1 {
  color: white;
}
#divProdImg > hr {
  background-image: radial-gradient(white, transparent);
  width: 50%;
  border-radius: 50%;
  height: 2px;
}

#divContProd {
  background-image: linear-gradient(#0d2037, #0d2037d5),
    url("../images/divBg3d.jpg");
  text-align: center;
}
#divContProd > h1 {
  color: white;
}
#divContProd > hr {
  background-image: radial-gradient(white, transparent);
  width: 50%;
  border-radius: 50%;
  height: 2px;
}
#galleryDiv {
  width: 80%;
  height: 0.2rem;
  color: transparent;
  background-image: radial-gradient(var(--brass), transparent);
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}
.formSection {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.lightbox {
  overflow: visible;
}
