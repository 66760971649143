body {
  margin: 0;
  background-color: white;
  color: white;
  font-family: "Oxygen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --oxford: #0d2037;
  --brass: #b8967e;
  --snow: #fbf7f7;
  --nypink: #db7b7b;
  --dsand: #f6d1b6;
}
h3 {
  font-family: "Lora";
  font-size: 3rem;
  font-style: normal;
  letter-spacing: 0.1rem;
  color: var(--brass);
}
h1 {
  font-family: "Lora";
  font-weight: 600;
  font-size: 5rem;
  font-style: normal;
  letter-spacing: 0.1rem;
  color: var(--brass);
}
p {
  font-family: "Lora";
  font-size: 1rem;
  color: black;
  text-align: justify;
}
@media screen and (max-width: 600px) {
  h1 {
    font-family: "Lora";
    font-weight: 600;
    font-size: 3rem;
    font-style: normal;
    letter-spacing: 0.1rem;
    color: var(--brass);
  }
  h3 {
    font-family: "Lora";
    font-size: 2rem;
    font-style: normal;
    letter-spacing: 0.1rem;
    color: var(--brass);
  }
}
@import url("https://fonts.googleapis.com/css?family=Comfortaa");
@import url("https://fonts.googleapis.com/css?family=Syncopate");
@import url("https://fonts.googleapis.com/css?family=Lora");
