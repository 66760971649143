.headerBody {
  position: sticky;
  top: 0px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  max-height: 5vh;
  align-items: center;
  padding-left: 2vw;
  padding-right: 5vw;
  background-color: rgba(255, 255, 255, 0.74);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.titleLogo {
  color: #0d2037;
  font-family: "Lora";
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  object-fit: scale-down;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  height: 5vh;
}
.logo {
  margin-right: 1rem;
  height: 4.5vh;
}
