.footerBody {
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-top-width: 0.5rem;
  border-color: var(--snow);
  background-color: var(--oxford);
  border-style: solid;
  min-height: min-content;
}
.footerContent {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 25%;
  padding-right: 25%;
  text-align: center;
}
